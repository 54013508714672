var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"1000px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑记录"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"客户名称"}},[_vm._v(" "+_vm._s(_vm.data.agent_name)+" ")]),_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入标题' } ]
          }]),expression:"['title', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入标题' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type', {
            rules: [
              { required: true, message: '类型' } ]
          }]),expression:"['type', {\n            rules: [\n              { required: true, message: '类型' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择类型","allow-clear":""}},_vm._l((_vm.issueTypeOptions),function(option){return _c('a-select-option',{key:option.slug,attrs:{"value":option.slug}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('a-form-item',{staticClass:"simiot-display-item",attrs:{"label":"日期"}},[_vm._v(" "+_vm._s(_vm.data.happen_date)+" ")]),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: '1' }]),expression:"['status', { initialValue: '1' }]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{key:"1",attrs:{"value":"1"}},[_vm._v(" 已处理 ")]),_c('a-select-option',{key:"0",attrs:{"value":"0"}},[_vm._v(" 未处理 ")])],1)],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('wang-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description', {
            rules: [
              { max: 65535, message: '最多65535个字符' } ]
          }]),expression:"['description', {\n            rules: [\n              { max: 65535, message: '最多65535个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }