<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑记录"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item
          label="客户名称"
          class="simiot-display-item"
        >
          {{ data.agent_name }}
        </a-form-item>

        <a-form-item label="标题">
          <a-input
            v-decorator="['title', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入标题' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`类型`">
          <a-select
            placeholder="请选择类型"
            v-decorator="['type', {
              rules: [
                { required: true, message: '类型' },
              ]
            }]"
            allow-clear
          >
            <a-select-option
              v-for="option in issueTypeOptions"
              :key="option.slug"
              :value="option.slug"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="日期"
          class="simiot-display-item"
        >
          {{ data.happen_date }}
        </a-form-item>

        <a-form-item label="状态">
          <a-select
            v-decorator="['status', { initialValue: '1' }]"
            allow-clear
          >
            <a-select-option key="1" value="1">
              已处理
            </a-select-option>
            <a-select-option key="0" value="0">
              未处理
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="描述">
          <wang-editor
            v-decorator="['description', {
              rules: [
                { max: 65535, message: '最多65535个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findAgentOptions } from '@/api/agent'
import { formatDate } from '@/utils/time'
import { findIssueTypeOptions, updateCustomerServiceIssues, findCustomerServiceIssueForm } from '@/api/customer_service_manage'

import WangEditor from '@/components/Editor/WangEditor'

export default {
  name: 'EditIssue',
  components: {
    WangEditor
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'issue' }),
      submitting: false,
      data: {},
      issueTypeOptions: [],
      agentOptions: [],
      loadingIssueTypeOption: true,
      loadingAgentOptions: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
    this.fetchIssueTypeOptions()
  },
  methods: {
    fetchFormData() {
      findCustomerServiceIssueForm(this.id).then((res) => {
        this.data = res.data
        this.data.status = this.data.status.toString()

        this.form.setFieldsValue(res.data)
      })
    },

    // 加载所属用户选项数据
    fetchAgentOptions(name) {
      if (!this.$lodash.trim(name)) {
        return
      }

      this.loadingAgentOptions = true
      findAgentOptions({ name: name }).then(res => {
        if (res.code === 0) {
          this.agentOptions = res.data
        }
        this.loadingAgentOptions = false
      })
    },

    // 加载问题类型选项
    fetchIssueTypeOptions() {
      this.loadingIssueTypeOption = true
      findIssueTypeOptions().then((res) => {
        if (res.code === 0) {
          this.issueTypeOptions = res.data
        }
        this.loadingIssueTypeOption = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true

          values.happen_date = formatDate(values.happen_date)

          updateCustomerServiceIssues(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
